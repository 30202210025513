"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getUrlForEnvAndApp;
var _environments = require("../environments");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function getUrlForEnvAndApp(environment, appName) {
  var _urls$environment;
  var urls = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, _environments.LOCAL, "https://".concat(appName === 'home' ? 'local' : 'local-shop', ".hellolingo.com")), _environments.RESEARCH, "https://".concat(appName === 'home' ? 'research' : 'shop-r', ".hellolingo.com")), _environments.DEV, "https://".concat(appName === 'home' ? 'dev' : 'shop-d', ".hellolingo.com")), _environments.QA, "https://".concat(appName === 'home' ? 'qa' : 'shop-q', ".hellolingo.com")), _environments.STG, "https://".concat(appName === 'home' ? 'stg' : 'shop-s', ".hellolingo.com")), _environments.PROD, "https://".concat(appName === 'home' ? 'www' : 'shop', ".hellolingo.com"));

  // Default to production url if environment isn't defined correctly
  return (_urls$environment = urls[environment]) !== null && _urls$environment !== void 0 ? _urls$environment : urls[_environments.PROD];
}