"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STG = exports.RESEARCH = exports.QA = exports.PROD = exports.LOCAL = exports.DEV = void 0;
// env = 'local' || 'research' || 'dev' || 'qa' || 'stg' || 'prod'

var LOCAL = exports.LOCAL = 'local';
var DEV = exports.DEV = 'dev';
var RESEARCH = exports.RESEARCH = 'research';
var QA = exports.QA = 'qa';
var STG = exports.STG = 'stg';
var PROD = exports.PROD = 'prod';