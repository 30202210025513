"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = replaceUrls;
var _getUrlForEnvAndApp = _interopRequireDefault(require("./getUrlForEnvAndApp"));
var _ukPaths = require("./ukPaths");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var appendUkToURL = function appendUkToURL(url) {
  var modifiedUrl = url;
  var pathSegments = new URL(url).pathname.split('/').filter(Boolean);
  if (pathSegments.length === 0) {
    return new URL('uk', url);
  }
  pathSegments.forEach(function (segment) {
    if (_ukPaths.UK_PATH.has(segment)) {
      var replaceWith = "uk/".concat(segment);
      modifiedUrl = url.replace(segment, replaceWith);
    }
  });
  return modifiedUrl;
};

// Replace domains/subdomains with the relative environment the application is running from
// e.g. https://www.hellolingo.com ==> http://localhost:3001 when running in local env
function replaceUrls(url, env) {
  var appendUk = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var sanitizedUrl = url;
  var domainRegex = /https?:\/\/(local-shop|shop|www)\.hellolingo\.com/;
  var match = url === null || url === void 0 ? void 0 : url.toLowerCase().match(domainRegex);
  if (match) {
    var appName = match[1] === 'shop' ? match[1] : 'home';
    var appUrl = (0, _getUrlForEnvAndApp["default"])(env, appName);
    sanitizedUrl = url.replace(domainRegex, appUrl);
    if (appName === 'home' && appendUk) {
      sanitizedUrl = appendUkToURL(sanitizedUrl);
    }
  }
  return sanitizedUrl;
}